import React from 'react';

const TermiteTreatment = () => {
  return (
    <div className="service-section">
      <h2>Termite Treatment at Pre-Construction Site</h2>
      <p>Chemical to Be Used: Premise (Imidacloprid 30.5% SC) – Bayer/Envu.</p>
    </div>
  );
};

export default TermiteTreatment;