import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage/HomePage';
import AboutPage from './components/AboutPage/AboutPage';
import ContactPage from './components/ContactPage';
import Appointment from './components/Appointment';
import InteriorDesign1 from './components/InteriorDesign/InteriorDesign1';
import PrivacyPolicy from './components/LegalPages/PrivacyPolicy';
import HomePodcast from './components/InteriorDesign/HomePodcast/HomePodcast';
import ThreeDApartment from './components/InteriorDesign/3d-designs/ThreeDApartment';
import ThreeDFurniture from './components/InteriorDesign/3d-designs/ThreeDFurniture';
import ConstructionServices from './components/ConstructionservicesPage';
import Sitemap from './components/Sitemap';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Routes>
            <Route path="/about" element={<AboutPage />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/appointment" element={<Appointment />} />
            <Route path="/interior-design" element={<InteriorDesign1 />} />
            <Route path="/interior-design/home-podcast-studio" element={<HomePodcast />} />
            <Route path="/interior-design/3d-apartment" element={<ThreeDApartment />} />
            <Route path="/interior-design/3d-furniture" element={<ThreeDFurniture />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/construction-services" element={<ConstructionServices />} />
            <Route path="/sitemap" element={<Sitemap />} />
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;