import React from 'react';

const Brickwork = () => {
  return (
    <div className="service-section">
      <h2>Brickwork CUM</h2>
      <p>High-quality brickwork for durable and strong walls.</p>
    </div>
  );
};

export default Brickwork;