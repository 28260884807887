import React from 'react';
import Menu from './Menu';
import Footer from './Footer/Footer';
import '../css/Sitemap.css';

const Sitemap = () => {
  return (
    <div className="sitemap-page">
      <Menu />
      <div className="wrapper">
        <div className="sitemap-section">
          <h1>Sitemap</h1>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about">About</a></li>
            <li><a href="/appointment">Appointment</a></li>
            <li><a href="/interior-design">Interior Design</a></li>
            <li><a href="/construction-services">Construction Services</a></li>
            <ul>
              <li><a href="/interior-design/home-podcast-studio">Home Podcast Studio</a></li>
              <li><a href="/interior-design/3d-apartment">3D Apartment</a></li>
            </ul>
            <li><a href="/contact">Contact</a></li>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Sitemap;