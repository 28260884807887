import React from 'react';

const ReinforcementSteel = () => {
  return (
    <div className="service-section">
      <h2>Reinforcement Steel TMT FE 500D KG</h2>
      <p>High-quality TMT steel bars for reinforced concrete structures.</p>
    </div>
  );
};

export default ReinforcementSteel;