import React, { useState } from 'react';
import Menu from './Menu';
import Footer from './Footer/Footer';
import '../css/ConstructionServices.css';
import ConstructionServicesContent from './ConstructionServices/ConstructionServicesContent';
import FoundationExcavation from './ConstructionServices/FoundationExcavation';
import PCC from './ConstructionServices/PCC';
import ReinforcementSteel from './ConstructionServices/ReinforcementSteel';
import RCCWork from './ConstructionServices/RCCWork';
import Brickwork from './ConstructionServices/Brickwork';
import Formwork from './ConstructionServices/Formwork';
import MurumFilling from './ConstructionServices/MurumFilling';
import ExternalPlaster from './ConstructionServices/ExternalPlaster';
import TermiteTreatment from './ConstructionServices/TermiteTreatment';
import ConstructionServicesEmailTemplate from './ConstructionServices/ConstructionServicesEmailTemplate';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

const ConstructionservicesPage = () => {
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    pincode: '',
    address: '',
    requirement: ''
  });
  const [submitting, setSubmitting] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);

    const uuid = uuidv4();
    Cookies.set('uuid', uuid);

    const response = await fetch('https://mongo.exinary.website/submit-eco-leads', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...formData,
        uuid,
        source: '/Construction-services Page',
      }),
    });

    if (response.ok) {
      console.log('Form submitted successfully');
      setShowForm(false);
      setShowThankYou(true);
    } else {
      console.log('Form submission failed');
    }
    setSubmitting(false);
  };

  return (
    <div className="construction-services-page">
      <Menu />
      <div className="wrapper">
        <div className="hero">
          <div className="construction-services-section">
            <ConstructionServicesContent />
            <FoundationExcavation />
            <PCC />
            <ReinforcementSteel />
            <RCCWork />
            <Brickwork />
            <Formwork />
            <MurumFilling />
            <ExternalPlaster />
            <TermiteTreatment />
            {!showThankYou && (
              <>
                <div className="quote-button-container">
                  <button className="quote-button" onClick={() => setShowForm(true)}>Get a Quote Today!</button>
                </div>
                {showForm && (
                  <form className="quote-form" onSubmit={handleSubmit}>
                    <label>Name</label>
                    <input
                      type="text"
                      name="name"
                      placeholder="Enter your name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                    />
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                    <label>Phone</label>
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Enter your phone number"
                      value={formData.phone}
                      onChange={handleInputChange}
                      required
                    />
                    <label>Pincode</label>
                    <input
                      type="text"
                      name="pincode"
                      placeholder="Enter your pincode"
                      value={formData.pincode}
                      onChange={handleInputChange}
                      required
                    />
                    <label>Address</label>
                    <input
                      type="text"
                      name="address"
                      placeholder="Enter your address"
                      value={formData.address}
                      onChange={handleInputChange}
                      required
                    />
                    <label>Requirement</label>
                    <textarea
                      name="requirement"
                      placeholder="Enter your requirement"
                      value={formData.requirement}
                      onChange={handleInputChange}
                      required
                    />
                    <button type="submit" disabled={submitting}>Submit</button>
                    <p>Please fill out all fields to submit the form</p>
                  </form>
                )}
              </>
            )}
            {showThankYou && (
              <div className="thank-you-message">
                <h2>Thank You!</h2>
                <p>Your request has been submitted successfully. We will get back to you soon.</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ConstructionservicesPage;