import React from 'react';

const Formwork = () => {
  return (
    <div className="service-section">
      <h2>Formwork SQM</h2>
      <p>Reliable formwork solutions for concrete structures.</p>
    </div>
  );
};

export default Formwork;