import React from 'react';

const ExternalPlaster = () => {
  return (
    <div className="service-section">
      <h2>External Plaster 1:4 and 18mm - 20mm thick SQM</h2>
      <p>High-quality external plastering for a smooth and durable finish.</p>
    </div>
  );
};

export default ExternalPlaster;