import React from 'react';

const MurumFilling = () => {
  return (
    <div className="service-section">
      <h2>Murum Filling CUM</h2>
      <p>Efficient murum filling for site preparation and leveling.</p>
    </div>
  );
};

export default MurumFilling;