import React from 'react';

const RCCWork = () => {
  return (
    <div className="service-section">
      <h2>RCC Work with M25 Concrete CUM</h2>
      <p>Reinforced Cement Concrete (RCC) work with M25 grade concrete for robust construction.</p>
    </div>
  );
};

export default RCCWork;