import React from 'react';

const FoundationExcavation = () => {
  return (
    <div className="service-section">
      <h2>Foundation Excavation CUM</h2>
      <p>Strong foundations start here! Our expert team ensures accurate soil excavation for safe and durable buildings.</p>
    </div>
  );
};

export default FoundationExcavation;