import React from 'react';

const PCC = () => {
  return (
    <div className="service-section">
      <h2>PCC 1:3:6 CUM</h2>
      <p>Providing Plain Cement Concrete (PCC) for a strong and stable base.</p>
    </div>
  );
};

export default PCC;